<template>
  <div :class="colClasses">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'CardListItem',
  props: {
    cols: { type: Number, default: 0 },
  },
  setup(props) {
    const colClasses = computed(() => ({
      'card-list__col': true,
      [`card-list__col--${props.cols}`]: props.cols > 1,
    }))

    return {
      colClasses,
    }
  },
}
</script>

<style scoped></style>
