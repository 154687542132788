<template>
  <div class="card-list">
    <slot v-if="!pending"></slot>
    <template v-else>
      <card-list-item v-for="index in cols" :key="index" :cols="cols">
        <block-placeholder height="422px" />
      </card-list-item>
    </template>
  </div>
</template>

<script lang="ts">
import BlockPlaceholder from '~/components/loaders/BlockPlaceholder.vue'
import CardListItem from '~/components/CardListItem.vue'

export default {
  name: 'CardList',
  components: { CardListItem, BlockPlaceholder },
  props: {
    cols: { type: Number, default: () => 0 },
    pending: { type: Boolean, default: () => false },
  },
}
</script>

<style lang="scss">
.card-list {
  display: flex;
  position: relative;
  margin: 0 -11px;
  flex-wrap: wrap;

  .home-card {
    max-width: 100%;
  }

  &__col {
    padding-right: 11px;
    padding-left: 11px;
    margin-bottom: 22px;

    &--2 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &--3 {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
    }

    &--4 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &--5 {
      flex: 0 0 calc(100% / 5);
      max-width: calc(100% / 5);
    }

    @include desktop {
      flex: 0 0 calc(100% / 3) !important;
      max-width: calc(100% / 3) !important;
      padding-right: 7px;
      padding-left: 7px;
      scroll-snap-align: center;
    }

    @include tablet {
      flex: 0 0 calc(100% / 2) !important;
      max-width: calc(100% / 2) !important;
    }

    @include mobile {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding-right: 7px;
      padding-left: 7px;
      scroll-snap-align: center;
    }
  }

  @include mobile {
    @include hide-scroll;
    overflow-x: auto;
    flex-wrap: nowrap;
    margin-right: -30px;
    margin-left: -30px;
    padding: 0 24px;
    scroll-snap-type: x mandatory;
  }
}
</style>
